<template>
    <card>
      <body-card style="margin: 0;border: none;">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.application') }} {{ $t('globalTrans.details') }}</h4>
          </template>
          <template v-slot:body>
                <div v-if="loading">
                  <Loading />
                </div>
                <b-overlay v-else>
                  <div class="p-3">
                    <b-row>
                      <b-col sm="12">
                        <div class="text-right">
                           <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                            <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                          </b-button>
                        </div>
                        <div>
                          <div class="mb-4 justify-content-end" style="display: flex; flex-wrap: wrap;">
                            <div class="text-right" style="width:50%"><b>{{ $t('globalTrans.application_date') }}</b> : {{
                              appDetail.created_at | dateFormat }}</div>
                          </div>
                          <div class="stepper-wrapper">
                            <div :class="item.value <= appDetail.status ? `stepper-item completed` : `stepper-item`"
                              v-for="(item, index) in stepList" :key="index">
                              <div class="step-counter">
                                <i v-if="item.value <= appDetail.status" class="ri-check-fill"></i>
                                <span v-else>{{ $n(index+1) }}</span>
                              </div>
                              <div class="step-name">{{ item.text }}</div>
                            </div>
                          </div>
                          <!-- Application view start -->
                          <div class="application-form-wrapper application-form-view-wrapper mt-5">
                            <div>
                              <h5
                                style="border-bottom: 2px solid #2f3542;width: 65%;margin: 3rem auto;padding-bottom: 5px;text-align: center;">
                                {{ $t('teaGarden.fertilizer_reqst_app') }}</h5>
                            </div>
                            <!-- garden info -->
                            <div class="application-itmes">
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.garden_info')" label-size="md"
                                  label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('teaGardenConfig.garden_name')" label-for="garden_name">
                                        <p v-if="appDetail.garden" class="input-field">{{ currentLocale === 'en' ? appDetail.garden.tea_garden_name_en : appDetail.garden.tea_garden_name_bn }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('teaGardenConfig.company_name')" label-for="company_name">
                                        <p v-if="appDetail.garden.tea_company" class="input-field">{{ currentLocale === 'en' ? appDetail.garden.tea_company.company_name_en : appDetail.garden.tea_company.company_name_bn }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                    <b-form-group :label="$t('teaGardenConfig.company_address')" label-for="address">
                                        <p v-if="appDetail.garden.tea_company" class="input-field">{{ currentLocale === 'en' ? appDetail.garden.tea_company.address_en : appDetail.garden.tea_company.address_bn }}</p>
                                    </b-form-group>
                                    </b-col>
                                     <b-col sm="6">
                                    <b-form-group :label="$t('teaGardenConfig.total_land')" label-for="total_land">
                                        <p v-if="appDetail.garden.land_lease_info" class="input-field">{{ $n(appDetail.garden.land_lease_info.total_land) }}</p>
                                    </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                    <b-form-group :label="$t('teaGarden.total_cultivate_land')" label-for="total_cultivate_land">
                                        <p v-if="appDetail.garden.land_lease_info" class="input-field">{{ $n(appDetail.total_cultivate_land) }}</p>
                                    </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                    <b-form-group :label="$t('teaGarden.last_year_tea_production')" label-for="last_year_tea_production">
                                        <p v-if="appDetail.garden.land_lease_info" class="input-field">{{ $n(appDetail.last_year_tea_production) }}</p>
                                    </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-form-group>
                              </div>
                            </div>
                             <!--proposed sale center info -->
                            <div class="application-itmes">
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('teaGarden.fertilizer_collection_place_proposed')" label-size="md"
                                  label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                             <!-- bcic sale center -->
                                <b-col sm="6">
                                  <h6 class="mb-2 font-weight-bold">{{$t('teaGarden.bcic_sale_center')}}</h6>
                                  <!-- center name -->
                                    <b-col sm="12">
                                      <b-form-group :label="$t('teaGardenConfig.distribution_center_name')" label-for="distribution_center_name">
                                        <p v-if="appDetail.garden" class="input-field">{{ getSaleCenterName(appDetail.proposed_bcic_sale_center_id)}}</p>
                                      </b-form-group>
                                    </b-col>
                                  <!-- center address -->
                                 <b-col sm="12">
                                        <b-form-group :label="$t('teaGardenConfig.distribution_center_address')" label-for="distribution_center_address">
                                          <p v-if="appDetail.garden" class="input-field">{{ getSaleCenterAddress(appDetail.proposed_bcic_sale_center_id)}}</p>
                                        </b-form-group>
                                      </b-col>
                                  </b-col>
                                 <!-- badc sale center -->
                                  <b-col sm="6">
                                    <h6 class="mb-2 font-weight-bold">{{$t('teaGarden.badc_sale_center')}}</h6>
                                    <!-- center name -->
                                    <b-col sm="12">
                                      <b-form-group :label="$t('teaGardenConfig.distribution_center_name')" label-for="proposed_badc_sale_center_id">
                                        <p v-if="appDetail.garden" class="input-field">{{ getSaleCenterName(appDetail.proposed_badc_sale_center_id)}}</p>
                                      </b-form-group>
                                    </b-col>
                                  <!-- center address -->
                                 <b-col sm="12">
                                        <b-form-group :label="$t('teaGardenConfig.distribution_center_address')" label-for="proposed_badc_sale_center_id">
                                          <p v-if="appDetail.garden" class="input-field">{{ getSaleCenterAddress(appDetail.proposed_badc_sale_center_id)}}</p>
                                        </b-form-group>
                                      </b-col>
                                  </b-col>
                                  </b-row>
                                </b-form-group>
                              </div>
                            </div>
                            <!-- fertilizer request info -->
                            <div class="application-itmes">
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('teaGarden.fertilizer_request') + ' ' + $t('globalTrans.m_ton')" label-size="md"
                                  label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                   <b-col sm="12">
                                    <table class="table table-sm table-bordered section-tree-view-table">
                                      <thead>
                                        <tr>
                                          <th class="text-center" v-for="(item ,index) in appDetail.proposals" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td class="text-center" v-for="(item ,index) in appDetail.proposals" :key="index">
                                            {{$n(item.reqst_qty)}}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </b-col>
                                  </b-row>
                                </b-form-group>
                              </div>
                            </div>
                             <!-- previous history -->
                            <div class="application-itmes">
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('teaGarden.fertilizer_received_history') + ' ' + $t('globalTrans.m_ton')" label-size="md"
                                  label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                   <!-- received histroy -->
                              <b-col sm="12">
                                <h6 class="mt-2">{{$t('teaGarden.fertilizer_received_history')}} {{$t('globalTrans.m_ton')}}</h6>
                                <table class="table table-sm table-bordered section-tree-view-table">
                                  <thead>
                                    <tr>
                                      <slot v-if="previousData.proposals && Object.keys(previousData.proposals).length > 1">
                                      <th class="text-center" v-for="(item ,index) in previousData.proposals" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                      </slot>
                                      <slot v-else>
                                      <th class="text-center" v-for="(item ,index) in appDetail.proposals" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                      </slot>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <slot v-if="previousData.proposals && Object.keys(previousData.proposals).length > 1">
                                        <td class="text-center" v-for="(item ,index) in previousData.proposals" :key="index">
                                           {{$n(item.receipt_qty)}}
                                        </td>
                                      </slot>
                                      <slot v-else>
                                        <td class="text-center" v-for="(item ,index) in appDetail.proposals" :key="index">
                                           {{$n(item.receipt_qty)}}
                                        </td>
                                      </slot>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-col>
                              <!-- used history -->
                                <b-col sm="12">
                                  <h6 class="mt-2">{{$t('teaGarden.fertilizer_used_history')}} {{$t('globalTrans.m_ton')}}</h6>
                                  <table class="table table-sm table-bordered section-tree-view-table">
                                    <thead>
                                      <tr>
                                        <th class="text-center" v-for="(item ,index) in appDetail.fertilizer_used_history" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td v-for="(item ,index) in appDetail.fertilizer_used_history" :key="index">
                                           {{$n(item.used_qty)}}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </b-col>
                                </b-row>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="12" class="text-left">
                        <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-overlay>
          </template>
      </body-card>
    </card>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import Loading from '@/components/loading/Details.vue'
import { fertilizerRequestView, lastYearFertilizerReqesHistory, fertilizerRequestViewPdf } from '../../../api/routes'
export default {
  name: 'Details',
  components: {
    Loading
  },
  data () {
    return {
      // loading: false,
      forwarLoad: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      appDetail: {
        id: 0
      },
      previousData: {
      proposals: [{}],
      fertilizer_used_history: [{}]
      },
      previousDataLoding: false,
      users: []
    }
  },
  created () {
    this.getAppDetail()
  },
  computed: {
    appId () {
      return this.$route.params.id
    },
    stepList () {
      const tcbStepList = [
          { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
          { value: 3, text: this.$t('teaGardenBtriService.assigned'), text_en: this.$t('teaGardenBtriService.assigned', 'en'), text_bn: this.$t('teaGardenBtriService.assigned', 'bn') },
          { value: 4, text: this.$t('globalTrans.forward'), text_en: this.$t('globalTrans.forward', 'en'), text_bn: this.$t('globalTrans.forward', 'bn') },
          { value: 5, text: this.$t('globalTrans.verified'), text_en: this.$t('globalTrans.verified', 'en'), text_bn: this.$t('globalTrans.verified', 'bn') },
          { value: 6, text: this.$t('globalTrans.processing'), text_en: this.$t('globalTrans.processing', 'en'), text_bn: this.$t('globalTrans.processing', 'bn') },
          { value: 10, text: this.$t('globalTrans.approved'), text_en: this.$t('globalTrans.approved', 'en'), text_bn: this.$t('globalTrans.approved', 'bn') }
      ]
      return tcbStepList
    },
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    async getAppDetail () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, fertilizerRequestView + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data
          this.getPreviousRequestHistory(result.data.garden_id)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 19)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, fertilizerRequestViewPdf + '/' + this.appId, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getFertilizerName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getSaleCenterName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerDistributionList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getSaleCenterAddress (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerDistributionList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.distrib_center_address_en : obj.distrib_center_address_bn
      }
    },
    async getPreviousRequestHistory (gardenId) {
      const paramsData = {
          garden_id: gardenId,
          id: this.$route.params.id,
          fiscal_year_id: this.appDetail.fiscal_year_id
      }
      this.previousDataLoding = true
      const result = await RestApi.getData(teaGardenServiceBaseUrl, lastYearFertilizerReqesHistory, paramsData)
        if (result.success) {
            const data = result.data
            if (data) {
                this.previousData = data
            }
        } else {
          this.previousData = {
            proposals: [],
            fertilizer_used_history: []
          }
        }
        this.previousDataLoding = false
    }
  }
}
</script>
<style scoped>
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
.tagTwo {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: hsl(250deg, 100%, 40%);
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  transition: clip-path 500ms;
}
.tagTwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: hsl(252deg, 100%, 60%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
}
</style>
